<template>
  <van-form class="payType">
    <van-cell>支付方式</van-cell>
    <div v-if="!disabled" class="action">
      <Van-icon
        name="add-o"
        type="primary"
        @click="showPayTypeSelect"
      ></Van-icon>
    </div>
    <CustomTable
      :scrollWidth="0"
      title="支付方式"
      :tableData="tableData"
      :columns="columns"
      :showPagination="false"
    ></CustomTable>
    <PayTypeSelector
      :visible="payTypeSelectorVisible"
      :list="payTypeList"
      @confirm="confirm"
    ></PayTypeSelector>
  </van-form>
</template>
<script>
import CustomTable from "./customTable.vue";
import PayTypeSelector from "./payTypeSelector.vue";
import { selector } from "../service/api/payType";
import { row } from "mathjs";

export default {
  name: "PayType",
  components: { CustomTable, PayTypeSelector },
  props: {
    list: { default: {}, type: Array },
    type: { default: "detail", type: String },
  },
  data() {
    return {
      columns: [
        {
          field: "payType",
          key: "payType",
          title: "支付方式",
          width: 50,
        },
        {
          field: "payAmount",
          key: "payAmount",
          title: "支付金额",
          edit: true,
          width: 40,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            let text = row[column.field];
            let disabled = this.type === "detail";
            return (
              <van-field
                value={text}
                disabled={disabled}
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "recText",
          key: "recText",
          title: "支付记录",
          width: 30,
        },
        {
          field: "",
          key: "e",
          title: "操作",
          width: "",
          center: "left",
          width: 20,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.type === "detail";
            return flag ? (
              ""
            ) : (
              <span class="action">
                <van-icon
                  name="delete-o"
                  on-click={() => this.deleteRow(rowIndex)}
                ></van-icon>
              </span>
            );
          },
        },
      ],
      tableData: [],
      disabled: false,
      payTypeSelectorVisible: false,
      payTypeList: [],
    };
  },
  watch: {
    list: {
      handler(newVal, oldVal) {
        this.tableData = newVal;
        this.getPayTypeList();
      },
      immediate: true,
      deep: true,
    },
    type: {
      handler(newVal, oldVal) {
        if (newVal === "detail") {
          this.disabled = true;
          // this.columns = [
          //   {
          //     field: "payType",
          //     key: "payType",
          //     title: "支付方式",
          //     width: 200,
          //   },
          //   {
          //     field: "payAmount",
          //     key: "payAmount",
          //     title: "支付金额",
          //     edit: true,
          //     width: 260,
          //   },
          //   {
          //     field: "recText",
          //     key: "recText",
          //     title: "支付记录",
          //     width: 300,
          //   },
          // ];
        } else {
          this.disabled = false;
          // this.columns = [
          //   {
          //     field: "payType",
          //     key: "payType",
          //     title: "支付方式",
          //     width: 200,
          //   },
          //   {
          //     field: "payAmount",
          //     key: "payAmount",
          //     title: "支付金额",
          //     width: 260,
          //     edit: true,
          //   },
          //   {
          //     field: "recText",
          //     key: "recText",
          //     title: "支付记录",
          //     width: 300,
          //   },
          // ];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    innerWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    inputChange(rowIndex, field, val) {
      this.$set(this.tableData, rowIndex, {
        ...this.tableData[rowIndex],
        [field]: val,
      });
    },
    // 校验数据
    validData() {
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];

        if (this.$utils.isEmpty(row.payTypeId)) {
          this.$utils.createError("第" + (i + 1) + "行支付方式不允许为空！");
          return false;
        }

        if (this.$utils.isEmpty(row.payAmount)) {
          this.$utils.createError(
            "第" + (i + 1) + "行支付方式支付金额不允许为空！"
          );
          return false;
        }

        if (!this.$utils.isFloat(row.payAmount)) {
          this.$utils.createError(
            "第" + (i + 1) + "行支付方式支付金额必须为数字！"
          );
          return false;
        }

        if (!this.$utils.isFloatGtZero(row.payAmount)) {
          this.$utils.createError(
            "第" + (i + 1) + "行支付方式支付金额必须大于0！"
          );
          return false;
        }

        if (!this.$utils.isNumberPrecision(row.payAmount, 2)) {
          this.$utils.createError(
            "第" + (i + 1) + "行支付方式支付金额最多允许2位小数！"
          );
          return false;
        }

        if (row.recText) {
          if (this.$utils.isEmpty(row.text)) {
            this.$utils.createError(
              "第" + (i + 1) + "行支付方式支付记录不允许为空！"
            );
            return false;
          }
        }
      }

      return true;
    },
    getTableData() {
      return this, this.tableData;
    },
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
    clear() {
      this.tableData = [];
    },
    getPayTypeList(params) {
      selector({
        ...params,
        available: true,
        ...this.formData,
      }).then((res) => {
        this.data = res.data.datas;
        this.payTypeList = this.data;
        this.tableData = this.tableData.map((el) => {
          const data = this.data.find((i) => i.id === el.payTypeId);
          return {
            ...el,
            payType: data?.name,
            id: el.payTypeId,
          };
        });
      });
    },
    showPayTypeSelect() {
      if (this.disabled) return;
      this.payTypeSelectorVisible = true;
    },
    emptyRow() {
      return {
        id: this.$utils.uuid(),
        payTypeId: "",
        payAmount: "",
        recText: false,
        text: "",
      };
    },
    confirm(row) {
      if (Object.keys(row).length) {
        this.tableData.push({
          payType: row.name,
          payAmount: 0,
          id: row.id,
          payTypeId: row.id,
          text: "",
        });
      }
      this.payTypeSelectorVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.payType {
  background-color: #efefef;
}
.action {
  background-color: #fff;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 26px;
  height: 36px;
}
</style>
