<template>
  <van-dialog
    v-model="show"
    class="dialog"
    show-cancel-button
    @close="confirm"
    @confirm="confirm"
  >
    <van-form style="position: relative; margin-top: 2rem">
      <van-cell class="tit">请选择支付方式:</van-cell>
      <!-- <van-cell-group inset>
        <van-sticky>
          <van-field v-model="formData.name" name="name" label="支付方式名称" />
          <van-cell>
            <van-button type="primary" @click="search">查询</van-button>
          </van-cell>
        </van-sticky>
      </van-cell-group> -->
      <van-list
        class="scrollList"
        :finished="true"
        :loading-text="'加载中...'"
        :finished-text="'没有更多了'"
        :error="true"
      >
        <van-cell
          ref="cell"
          v-for="item in payTypeList"
          :key="item.name"
          :title="item.name"
          @click="showActive(item)"
        />
      </van-list>
    </van-form>
    <!-- <van-cell style="position: absolute;bottom:0;">
      <van-button round block type="primary" @click="confirm">
        提交
      </van-button>
    </van-cell> -->
  </van-dialog>
</template>
<script>
import { AVAILABLE } from "../utils/constants";
// 导入样式
export default {
  name: "payTypeSelect",
  components: {},
  props: {
    visible: false,
    list: Array,
  },
  computed: {
    payTypeList() {
      return this.list;
    },
  },
  data() {
    return {
      show: false,
      data: [],
      formData: { code: "", name: "", available: true },
      columns: [
        {
          label: "编号",
          prop: "id",
        },
        {
          label: "名称",
          prop: "name",
        },
        {
          label: "状态",
          prop: "available",
        },
      ],
      statusList: AVAILABLE,
      currentRow: {},
    };
  },
  watch: {
    visible(newVal, oldVal) {
      this.show = newVal;
    },
  },
  created() {},
  methods: {
    search() {
      this.payTypeList = this.data.filter((el) => {
        return el.name.includes(this.formData.name);
      });
    },
    showActive(item) {
      this.currentRow = item;
      console.log(item, this.$refs.cell);
      const exitIndex = this.payTypeList.findIndex((i) => i.id === item.id);
      this.$refs.cell.forEach((el, index) => {
        el.classList.remove("active");
        if (index == exitIndex) {
          el.classList.add("active");
        }
      });
    },
    confirm() {
      this.$emit("confirm", this.currentRow);
      this.show = false;
      this.currentRow={}
    },
  },
};
</script>
<style lang="less" scoped>
.active {
  background: #1989fa;
}
.dialog {
  // height: 65%;
}
.tit {
  border-bottom: 1px solid #575555;
}
::v-deep .van-dialog__content {
  height: 100%;

  .van-form {
    height: 100%;
  }
}

.scrollList {
  height: calc(100% - 185px);
                
  overflow: auto;
}
::v-deep .van-dialog__footer {
  position: sticky;
  bottom: 0;
}
</style>
