import requests from '../request'
const baseUrl = '/basedata/paytype';
const selectorBaseUrl = '/selector';

export function selector(params) {
    return requests(
        {
            url: selectorBaseUrl + '/paytype',
            params,
        }
    );
}

export function loadPayType(ids) {
    return requests(
        {
            url: selectorBaseUrl + '/paytype/load',
            data: ids,
        },
        {
            contentType: ContentTypeEnum.JSON,
            region,
        },
    );
}

export function getOrderPayType(params) {
    return requests(
        {
            methods: 'get',
            url: selectorBaseUrl + '/paytype/order',
            params,
        },

    );
}

/**
 * 查询列表
 */
export function query(params) {
    return requests(
        {
            url: baseUrl + '/query',
            params,
        },

    );
}

/**
 * 根据ID查询
 * @param id
 */
export function get(id) {
    return requests(
        {
            url: baseUrl,
            params: {
                id: id,
            },
        },

    );
}

/**
 * 新增
 * @param data
 */
export function create(data) {
    return requests(
        {
            url: baseUrl,
            data,
        },
        {
            contentType: ContentTypeEnum.FORM_URLENCODED,
        },
    );
}

/**
 * 修改
 * @param data
 */
export function update(data) {
    return requests(
        {
            url: baseUrl,
            data,
        },
        {
            contentType: ContentTypeEnum.FORM_URLENCODED,
        },
    );
}
